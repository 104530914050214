import "./L2TxLogCard.css";
import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Fade, Tooltip } from "@mui/material";
import { LegacyCwebTxLoadedGql } from "../../generated/graphql";
import { handleTxIds } from "./L2TxLogCard";
import CopyIcon from "components/CopyIcon";

const MOBILE_WIDTH = 450;

type LegacyCwebTxLoadedSectionProps = {
  data: LegacyCwebTxLoadedGql;
  width: any;
};

const LegacyCwebTxLoadedSection = (props: LegacyCwebTxLoadedSectionProps) => {
  const { data, width } = props;
  const [, setSearchParams] = useSearchParams();

  const handleAmount = (amountNum: number) => {
    if (amountNum) {
      const value = amountNum / 1150;
      const decimals = 2;
      const amount = "USD " + value.toFixed(decimals);
      return amount;
    } else return "N/A";
  };
  return (
    <div
      className={
        width > MOBILE_WIDTH
          ? "L2TxLogCard-data-table"
          : "L2TxLogCard-data-table-mobile"
      }
    >
      <div>
        <Typography className="BoldFont16" align="left">
          L1 Parent ID
        </Typography>
        <div className="L2TxId-row">
          <Typography className="BoldFont14" align="center" gutterBottom>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={<div id="TxId-tooltip">{data.l1Txid}</div>}
            >
              <div
                onClick={() => {
                  setSearchParams({ search: data.l1Txid });
                }}
              >
                {handleTxIds(data.l1Txid)}
              </div>
            </Tooltip>
          </Typography>
          <CopyIcon data={data.l1Txid} />
        </div>
      </div>
      <div>
        <Typography className="BoldFont16" align="left">
          Amount (USD)
        </Typography>
        <Typography className="BoldFont14" align="center" gutterBottom>
          {handleAmount(Number(data.amount))}
        </Typography>
      </div>
    </div>
  );
};
export default memo(LegacyCwebTxLoadedSection);

import { useEffect, useMemo, useState } from "react";
import Fuse, { FuseOptionKey } from "fuse.js";

type TUseSearchProperties<T> = {
  searchString: string;
  list: Array<T>;
  keys: Array<FuseOptionKey<T>>;
};

function useSearch<T = unknown>({
  list,
  searchString,
  keys,
}: TUseSearchProperties<T>): Array<T> {
  const fuse = new Fuse(list, {
    isCaseSensitive: true,
    includeMatches: true,
    includeScore: true,
    shouldSort: true,
    keys: keys,
  });
  const [searchedList, setSearchedList] = useState<Array<T>>(list);

  useEffect(() => {
    if (!searchString) {
      setSearchedList(list);
      return;
    }
    const searchResult = fuse.search(searchString).map((res) => res.item);
    setSearchedList(searchResult);
  }, [list, searchString]);
  return useMemo(() => searchedList, [searchedList]);
}

export default useSearch;

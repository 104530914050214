import "./styles.css";
import Typography from "@mui/material/Typography";
import tar from "tar-stream";
import * as pako from "pako";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import { useEffect, useState, memo } from "react";
import { TransformedTemplate } from "hooks/useGlobalIndex/types";
import TargetInstances from "./TargetInstances";
import convertUint8ArrayToString from "utils/convertUint8ArrayToString";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import CardTitle from "./CardTitle";
import {
  L2TxLogMuiCard,
  MOBILE_WIDTH,
  handleTxIds,
} from "../L2TxLogCard/L2TxLogCard";
type ContactProps = {
  width: number;
  data: TransformedTemplate;
};

interface FileObject {
  [key: string]: Uint8Array;
}

const Contract = (props: ContactProps) => {
  const { width, data } = props;
  const [packageJson, setPackageJson] = useState<string | null>(null);
  const [onchainData, setOnchainData] = useState<string | null>(null);
  const [engine, setEngine] = useState<object | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [keywords, setKeywords] = useState<string | null>(null);
  const [homepage, setHomepage] = useState<string | null>(null);
  const [bugsUrl, setbugsUrl] = useState<string | null>(null);

  const [files, setFiles] = useState({});
  const [openOnchain, setOpenOnchain] = useState(false);
  const [openTargetInstances, setOpenTargetInstances] = useState(false);

  useEffect(() => {
    if (
      typeof files === "object" &&
      !Array.isArray(files) &&
      Object.keys(files).length > 0
    ) {
      for (const [key, value] of Object.entries(files)) {
        if (key === "package/cweb_dist/onchain/index.js") {
          setOnchainData(convertUint8ArrayToString(value));
        } else if (key === "package/package.json") {
          const packageJsonData = JSON.parse(convertUint8ArrayToString(value));
          setPackageJson(packageJsonData);

          if (packageJsonData.engines) {
            const engines = packageJsonData.engines;
            setEngine(engines);
          }
          if (packageJsonData.homepage) {
            setHomepage(packageJsonData.homepage);
          }
          if (packageJsonData.description) {
            setDescription(packageJsonData.description);
          }
          if (packageJsonData.keywords) {
            setKeywords(packageJsonData.keywords);
          }
          if (packageJsonData.bugs?.url) {
            setbugsUrl(packageJsonData.bugs.url);
          }
        }
      }
    }
  }, [files]);

  useEffect(() => {
    if (data?.details?.pkg_mirror?.url) {
      const packageUrl = data.details.pkg_mirror.url;

      const fetchAndExtractFile = async () => {
        try {
          const response = await fetch(packageUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          // Convert the response to an ArrayBuffer
          const arrayBuffer = await response.arrayBuffer();

          // Decompress the .tgz file
          const decompressed = pako.ungzip(new Uint8Array(arrayBuffer));

          // Create an extraction stream
          const extract = tar.extract();
          const files: FileObject = {};

          extract.on("entry", (header: any, stream: any, next: any) => {
            let fileData: Uint8Array[] = [];
            let onchainDataChunks: Uint8Array[] = []; // To collect data for "onchain/index.js"

            stream.on("data", (chunk: Uint8Array) => {
              fileData.push(chunk);
              if (header.name === "package/cweb_dist/onchain/index.js") {
                onchainDataChunks.push(chunk); // Collect chunks for "onchain/index.js"
              }
            });

            stream.on("end", () => {
              const uint8Array = new Uint8Array(
                fileData.reduce<number[]>(
                  (acc, val) => acc.concat(Array.from(val)),
                  []
                )
              );
              files[header.name] = uint8Array;
              next(); // Ready for next entry
            });

            stream.resume();
          });

          extract.on("finish", () => {
            setFiles(files);
          });

          // Pass the decompressed data to the tar extractor
          extract.end(decompressed);
        } catch (error) {
          console.error("Error extracting file:", error);
        }
      };

      fetchAndExtractFile();
    }
  }, [data]);
  function Row(
    row: any,
    title: string,
    code: boolean,
    isOpen: boolean,
    toggleOpen: (val: boolean) => void
  ) {
    const handleLineClick = (line: string) => {
      navigator.clipboard
        .writeText(line)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((err) => {
          toast.error("Failed to copy");
          console.error("Failed to copy: ", err);
        });
    };

    return (
      <div style={{ width: "100%" }}>
        <TableCell
          onClick={() => toggleOpen(!isOpen)}
          className="operationCollapseCell"
          sx={{ width: "100%" }}
        >
          <IconButton aria-label="expand row" size="small">
            {isOpen ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BoldBlueFont16">{title}</Typography>
        </TableCell>
        <TableCell
          sx={{ width: "100% !important" }}
          className="operationCollapseCell"
        >
          <Collapse
            onClick={() => code && handleLineClick(row)}
            in={isOpen}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100% !important" }}
          >
            {code ? (
              <div
                id="collapse_container"
                className="BoldFont14 scrollable-content"
              >
                <pre id="code">{row}</pre>
              </div>
            ) : (
              <div>
                {(row && Array.isArray(row) && row.length === 0) ||
                !row ||
                row === null ||
                row === "" ? (
                  <Typography className="BoldFont14" align="center">
                    not provided
                  </Typography>
                ) : (
                  <TargetInstances data={row}></TargetInstances>
                )}
              </div>
            )}
          </Collapse>
        </TableCell>
      </div>
    );
  }

  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100, padding: "20px" }}>
        <CardTitle title="Coinweb Contract" />

        <div className="grid_container">
          {data?.details?.contract_module && (
            <div>
              <Typography className="BoldFont16" align="left">
                Contract-Module-Id
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14" align="center">
                  <>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            width: "min-content !important",
                            backgroundColor: "#4f4f72 !important",
                            padding: "0px !important",
                            marginTop: "-1px !important",
                          },
                        },
                      }}
                      disableFocusListener
                      TransitionComponent={Fade}
                      title={
                        <div id="TxId-tooltip">
                          {data?.details?.contract_module}
                        </div>
                      }
                    >
                      <div className="L2TxId-container">
                        {handleTxIds(data?.details?.contract_module)}
                      </div>
                    </Tooltip>
                  </>
                </Typography>
                <CopyIcon data={data?.details?.contract_module} />
              </div>
            </div>
          )}
          {data?.alias && (
            <div>
              <Typography className="BoldFont16" align="left">
                Contract Alias
              </Typography>
              <div className="L2TxId-row">
                <Typography className="BoldFont14" align="center">
                  {data?.alias}
                </Typography>
              </div>
            </div>
          )}
          {data?.details?.pkg_mirror?.url && (
            <div>
              <Typography className="BoldFont16" align="left">
                Javascript Package
              </Typography>
              <Typography className="BoldFont14" align="center">
                <a className="BoldFont14" href={data?.details?.pkg_mirror?.url}>
                  Click here to download the packages
                </a>
              </Typography>
            </div>
          )}
          <div>
            <Typography className="BoldFont16" align="left">
              Engines
            </Typography>
            <div className="L2TxId-row">
              <Typography
                className="BoldFont14"
                align="center"
                sx={{
                  overflowWrap: "anywhere",
                }}
              >
                {engine !== null
                  ? Object.entries(engine).map(
                      ([key, value]) => `${key}: ${value} `
                    )
                  : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Description
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {description !== null ? description : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Keywords
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {keywords !== null ? keywords : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Homepage
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {homepage !== null ? homepage : "not provided"}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className="BoldFont16" align="left">
              Bugs Url{" "}
            </Typography>
            <div className="L2TxId-row">
              <Typography className="BoldFont14" align="center">
                {bugsUrl !== null ? bugsUrl : "not provided"}
              </Typography>
            </div>
          </div>
        </div>
        {onchainData &&
          Row(
            onchainData,
            "Smart Contract Onchain Code",
            true,
            openOnchain,
            setOpenOnchain
          )}
        <div>
          {Row(
            data?.details?.target_instances,
            "Known Instances",
            false,
            openTargetInstances,
            setOpenTargetInstances
          )}
        </div>
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(Contract);

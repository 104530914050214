import { useEffect, useMemo, useState } from "react";
import { Data, InterpreterObjectType, OriginalContractTemplate, TransformedTemplate } from "./types";
import yaml from "js-yaml";
import { Suggestions } from "utils/types";
import convertContractsToSuggestions from "utils/convertContractsToSuggestions";
import convertInterpretersToSuggestions from "utils/convertInterpretersToSuggestions";
import { GLOBAL_INDEX_URL } from "conf";

export const useGlobalIndex = () => {
  const [contractTemplates, setContractTemplates] = useState<
    Record<string, TransformedTemplate>
  >({});
  const [suggestions, setSuggestions] = useState<Suggestions[]>([]);
  const [interpreters, setInterpreters] = useState<Record<string, InterpreterObjectType> | null>(null);
  const [loading, setLoadin] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getGlobalIndex = async () => {
      try {
      const response = await fetch(GLOBAL_INDEX_URL);
      const text = await response.text();

      const parsedData = yaml.load(text) as Data;
      if (
        parsedData?.interpreters &&
        parsedData?.contract_templates
      ) {
        const data: Record<string, OriginalContractTemplate> =
          parsedData.contract_templates ?? {};
        const transformedData: Record<string, TransformedTemplate> = {};
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const template = data[key];
            transformedData[template.contract_module] = {
              alias: key,
              details: template,
            };
          }
        }
        setInterpreters(parsedData.interpreters);
        setContractTemplates(transformedData);
        const interpreterSuggestions = convertInterpretersToSuggestions(
          parsedData.interpreters
        );
        const contractSuggestions = convertContractsToSuggestions(
          parsedData.contract_templates
        );

        setSuggestions([...interpreterSuggestions, ...contractSuggestions]);
      }
    } catch(e) {
      setError(e as Error);
    } finally {
      setLoadin(false);
    }
    }

    if (!suggestions.length || !interpreters) {
      void getGlobalIndex();
    }
  }, [interpreters, suggestions.length]);

  useEffect(() => {
    let interpreterSuggestions: Suggestions[] = [];
    if (interpreters) {
      interpreterSuggestions =
        convertInterpretersToSuggestions(interpreters);
    }

    const contractSuggestions =
      convertContractsToSuggestions(contractTemplates);

    let combinedSuggestions = [
      ...interpreterSuggestions,
      ...contractSuggestions,
    ];
    setSuggestions(combinedSuggestions);
  }, [interpreters, contractTemplates]);

  return useMemo(
    () => ({contractTemplates, suggestions, interpreters, loading, error}),
    [contractTemplates, suggestions, interpreters, loading, error]
  );
}
import "./L2TxLogCard.css";
import { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { handleTxIds, MOBILE_WIDTH} from "./L2TxLogCard";
import { trimStrings } from "utils/trimJsonString";
import { ContactExecutedGql } from "../../generated/graphql";
import CopyIcon from "components/CopyIcon";
import { Fade, Tooltip } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type TxContactExecutedSectionProps = {
  data: ContactExecutedGql;
  width: any;
  rawData: any;
};


const TxContactExecutedSection = (props: TxContactExecutedSectionProps) => {
  const { data, width, rawData } = props;
  const [, setSearchParams] = useSearchParams();

  function RenderTxIdTypography(prop: { txid: string }) {
    return (
      <div className="L2TxId-row" style={{ margin: "5px auto 10px auto" }}>
        <Typography className="BoldFont14 L2TxId-container" align="center">
          {prop.txid ? (
            <>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#4f4f72 !important",
                      padding: "0px !important",
                      marginTop: "-1px !important",
                    },
                  },
                }}
                disableFocusListener
                TransitionComponent={Fade}
                title={<div id="TxId-tooltip">{prop.txid}</div>}
              >
                <div
                  className="L2TxId-container"
                  onClick={() => {
                    setSearchParams({ search: prop.txid });
                  }}
                  id="TxId-container"
                >
                  {handleTxIds(prop.txid)}
                </div>
              </Tooltip>
            </>
          ) : (
            "N/A"
          )}
        </Typography>

        <CopyIcon data={prop.txid} />
        <img
          className="linkArrow"
          src={ActiveSwipe}
          style={{ transform: "rotate(180deg)", marginLeft: "5px" }}
          alt="swipe"
          onClick={() => {
            setSearchParams({ hash: prop.txid });
          }}
        ></img>
      </div>
    );
  }

  function Row(props: { row: any }) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [contentOpen, setContentOpen] = useState(false);
    const [issuerOpen, setIssuerOpen] = useState(false);
    const [opsArray, setOpsArray] = useState(() => {
      return new Array(row[0]?.ops?.length).fill(false); // Create an array filled with false values
    });
    const handleOpsArray = (index: number) => {
      setOpsArray((prevState) => {
        const newArray = [...prevState]; // Create a copy of the original array
        newArray[index] = !newArray[index]; // Toggle the boolean value at the specified index
        return newArray; // Return the updated array
      });
    };
    const handleContent = (val: any) => {
      if (val || val[0]) {
        const contentKeys = Object.keys(val[0] ?? val);
        const contentValues = Object.values(val[0] ?? val);
        if (
          contentKeys[0] &&
          contentKeys[0] === "body" &&
          contentKeys[1] &&
          contentKeys[1] === "fees_stored" &&
          contentKeys[2] &&
          contentKeys[2] === "key"
        ) {
          return (
            <div>
              <div className="callOpContainer">
                <Typography className="BoldWhiteFont14">Body</Typography>

                <pre
                  style={{
                    height: "100%",
                    overflow: "scroll",
                    marginBottom: "0px !important",
                  }}
                >
                  <Typography className="DarkThinFont10 DisplayJsonRaw">
                    {JSON.stringify(contentValues[0], null, 2) ?? "N/A"}
                  </Typography>
                </pre>
              </div>
              <div
                className="callOpContainer"
                style={{ marginTop: "5px !important" }}
              >
                <Typography className="BoldWhiteFont14">Fees Stored</Typography>
                <Typography className="DarkThinFont10 DisplayJsonRaw">
                  {JSON.stringify(contentValues[1], null, 2) ?? "N/A"}
                </Typography>
              </div>
              <div className="callOpContainer">
                <Typography
                  className="BoldWhiteFont14"
                  sx={{ marginTop: "10px" }}
                >
                  Key{" "}
                </Typography>
                <Typography className="DarkThinFont10  DisplayJsonRaw">
                  {JSON.stringify(contentValues[2], null, 2) ?? "N/A"}
                </Typography>
              </div>
            </div>
          );
        }
      }
    };

    return (
      <div style={{ width: "100%" }}>
        {/* <L2TxLogDivider variant="middle" /> */}

        <TableCell
          onClick={() => setOpen(!open)}
          className="operationCollapseCell"
        >
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: "rgb(0, 195, 255)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: "rgb(0, 195, 255)" }} />
            )}
          </IconButton>
          <Typography className="BoldBlueFont16"> Operation Details</Typography>
        </TableCell>

        <TableCell className="operationCollapseCell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              {row[0]?.ops?.map((op: any, index: number) => {
                const opType = Object.keys(op)[0]; // Get the type of the operation
                const opValue = Object.values(op); // Get the type of the operation
                if (opType === "Verified") {
                  const argValues = Object.values(opValue[0] ?? opValue); // Get the type of the operation
                  return (
                    <>
                      <div style={{ marginTop: "10px" }}>
                        <TableCell
                          onClick={() => handleOpsArray(index)}
                          className="operationCollapseCell"
                        >
                          <IconButton aria-label="expand row" size="small">
                            {opsArray[index] ? (
                              <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                            ) : (
                              <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                            )}
                          </IconButton>
                          <Typography className="BoldWhiteFont14">
                            Forwarded Claim
                          </Typography>
                        </TableCell>
                        <Collapse
                          in={opsArray[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div>
                            <TableCell
                              onClick={() => setContentOpen(!contentOpen)}
                              className="operationCollapseCell"
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                            >
                              <IconButton aria-label="expand row" size="small">
                                {contentOpen ? (
                                  <KeyboardArrowUpIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{ color: "rgb(0, 195, 255)" }}
                                  />
                                )}
                              </IconButton>
                              <Typography
                                className="BoldBlueFont14"
                                sx={{ paddingTop: "0px", width: "auto" }}
                              >
                                Content
                              </Typography>
                            </TableCell>
                            <Collapse
                              in={contentOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              {handleContent(argValues[0])}
                            </Collapse>
                          </div>
                          <div>
                            <div>
                              <TableCell
                                onClick={() => setIssuerOpen(!issuerOpen)}
                                className="operationCollapseCell"
                              >
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  {issuerOpen ? (
                                    <KeyboardArrowUpIcon
                                      sx={{ color: "rgb(0, 195, 255)" }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      sx={{ color: "rgb(0, 195, 255)" }}
                                    />
                                  )}
                                </IconButton>
                                <Typography
                                  className="BoldBlueFont14 "
                                  sx={{ paddingTop: "0px", width: "auto" }}
                                >
                                  Issuer
                                </Typography>
                              </TableCell>
                              <Collapse
                                in={issuerOpen}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Typography className="DarkThinFont10 DisplayJsonRaw">
                                  {JSON.stringify(argValues[1], null, 2)}
                                </Typography>
                              </Collapse>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <div style={{ marginTop: "10px" }}>
                      <TableCell
                        onClick={() => handleOpsArray(index)}
                        className="operationCollapseCell"
                      >
                        <IconButton aria-label="expand row" size="small">
                          {opsArray[index] ? (
                            <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                          )}
                        </IconButton>
                        <Typography className="BoldWhiteFont14">
                          Argument
                        </Typography>
                      </TableCell>
                      <Collapse
                        in={opsArray[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Typography className="DarkThinFont10 DisplayJsonRaw">
                          {JSON.stringify(opValue, null, 2)}
                        </Typography>
                      </Collapse>
                    </div>
                  );
                }
              })}
            </div>
          </Collapse>
        </TableCell>
      </div>
    );
  }

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div>
        <Typography className="BoldBlueFont16">Contract executed</Typography>
      </div>
      <div id="Carousel" style={{ textIndent: "0px !important" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div className="ParentsCarousel-inner" style={{ height: "100%" }}>
            <div
              style={{ marginTop: "10px" }}
              className="L2TxLogCard-data-table  L2TxLogInfo-container"
            >
              <div className="gridRightBorder">
                <Typography className="BoldFont16">Details:</Typography>
                {rawData[0]?.callOp?.smart_contract && (
                  <div className="callOpContainer">
                    <Typography className="BoldWhiteFont14">
                      smart contract:
                    </Typography>
                    {rawData[0]?.callOp?.smart_contract
                      .ComputationBlockContract ? (
                      <RenderTxIdTypography
                        txid={
                          rawData[0]?.callOp?.smart_contract
                            ?.ComputationBlockContract
                        }
                      />
                    ) : (
                      <Typography
                        sx={{ margin: "5px 0px 10px 0px" }}
                        className="BoldFont14"
                      >
                        {JSON.stringify(
                          trimStrings(rawData[0]?.callOp?.smart_contract),
                          null,
                          2
                        )}
                      </Typography>
                    )}
                    {rawData[0]?.callOp?.number_of_arguments && (
                      <div className="callOpContainer">
                        <Typography className="BoldWhiteFont14">
                          number of operations:
                        </Typography>
                        <Typography className="BoldFont14">
                          {rawData[0]?.callOp?.number_of_arguments}
                        </Typography>
                      </div>
                    )}
                    {rawData[0]?.callOp?.resources && (
                      <div className="callOpContainer">
                        <Typography className="BoldWhiteFont14">
                          resources:
                        </Typography>
                        <Typography
                          className="BoldFont14"
                          sx={{ margin: "5px 0px 10px 0px" }}
                        >
                          {rawData[0]?.callOp?.resources}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                <Row row={rawData} />
              </div>
              <div>
                {data?.children?.map((child: any, index: number) => {
                  return (
                    <div key={index}>
                      <Typography className="BoldFont16">
                        Child Tx #{index + 1}
                      </Typography>
                      <RenderTxIdTypography txid={child} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(TxContactExecutedSection);
